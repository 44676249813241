import React, { useEffect, useState } from "react";
import "./BeforeAfterImage.scss";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import Card from "../txn_card.webp";
import Card2 from "../../assets/favicon copy.png";
import { Container } from "react-bootstrap";
// import mint from "../../assets/image/png/mint.png";

const delimiterIconStyles = {
  // width: "46px",
  width: "53px",
  height: "53px",
  backgroundSize: "contain",
  borderRadius: "none",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  // backgroundImage: "url(https://i.postimg.cc/MpKfp8tv/favicon-copy.png)",
  backgroundImage: "url(https://i.postimg.cc/v8zv059N/Picture7.png)",
};
const BeforeAfterImage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // setWindowWidth(window.innerWidth);
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    const handleResize = () =>
    setIsMobile(
        window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false)
      );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const SECOND_IMAGE = {
    imageUrl:
      !isMobile
        ? "https://i.postimg.cc/ryPhGwnQ/Clean.png"
        : "https://i.postimg.cc/13Ctj5Dz/after.png",
  };
  const FIRST_IMAGE = {
    imageUrl:
      !isMobile
        ? "https://i.postimg.cc/NjsqSMtm/Dirty.png"
        : "https://i.postimg.cc/cJvHyycB/before.png",
  };

  return (
    <Container style={{ width: isMobile? "100%" : "17.5rem", padding: 0 }}>
      <ReactBeforeSliderComponent
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
        delimiterIconStyles={delimiterIconStyles}
        currentPercentPosition={7}
      />
    </Container>
  );
};

export default BeforeAfterImage;
